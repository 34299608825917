<template>
    <div class="share_article">
        <Header style="padding: 0px 16px 0px;" @appOpen="followedBtn"></Header>
        <div class="share_content">
            <div class="share_video">
                <div class="title_img">
                    <img class="audio_img" :src="voiceList.avatar" alt="" >
                    <p class="audio_title">{{voiceList.title}}</p>
                </div>
                <div class="play_audio">
                    <audio  :src="voiceList.url"
                        @timeupdate="getCurr"
                        @pause="is_stop=true"
                        @play="is_stop=false"
                        autoplay="autoplay"
                        ref="audio"
                        @canplay="showLong"
                        ></audio>
                    <van-slider v-model="progress" active-color="#333333" @change="changeLong">
                        <template #button>
                            <div class="custom-button" :style="toTime(curr) <= '00:10' ? 'margin-left: 33px':'margin-right: 40px'">
                                {{ toTime(curr) + '/' + toTime(duration)}}
                            </div>
                        </template>
                    </van-slider>
                    <div class="ctol">
                        <van-icon size="80px" color="#333333" name="play-circle" v-if="is_stop" @click="plays"/>
                        <van-icon size="80px" color="#333333" name="pause-circle" v-else @click="plays"/>
                    </div>
                </div>
            </div>
            <div class="lecturer_message">
                <p class="lecturer">讲师介绍</p>
                <div class="share_content_message is-flex">
                    <div class="share_message_left is-flex">
                        <img :src="voiceList.avatar" alt="" class="head_portrait">
                        <div class="name_time">
                            <p class="name">{{voiceList.name}}</p>
                            <p class="time">{{voiceList.created_at}}</p>
                        </div>
                    </div>
                    <div class="share_message_right is-flex">
                        <button class="followed_btn" @click="followedBtn">
                            <img v-if="voiceList.has_following === false" src="../assets/share_followed.png" alt="" >
                            {{voiceList.has_following === false ? '关注': '已关注'}}
                        </button>
                    </div>
                </div>
                <div class="introduce" v-html="voiceList.describe">
                    {{voiceList.describe}}
                </div>
            </div>
        </div>
        <Footer isShare="voice" :detail="voiceList" @appOpen="followedBtn"/>
    </div>
</template>
<script>
import {
  requestDataTGPMethod,
} from "../utils/appManager";
import VueAliplayerV2 from "vue-aliplayer-v2";
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
    components:{
        Header,
        Footer,
        VueAliplayerV2
    },
    data() {
    return {
        progress: 0,
        is_stop: true,
        duration: 0,
        curr: 0,
        videoHeight: "height:200px",
        voiceList:{},
        useAlilayer: true,
        voiceID:''
    };
  },
  mounted () {
      this.requestLivingData();
  },
  created () {
      window["getBannerData"] = (data) => {
          window.document.title = data.data.voice.title

          this.voiceList = data.data.voice
    };
  },
  methods:{
      toTime(sec) { //秒数转化为mm:ss形式
        let s = sec % 60 < 10 ? ('0' + sec % 60) : sec % 60
        let min = Math.floor(sec / 60) < 10 ? ('0' + Math.floor(sec / 60)) : Math.floor(sec / 60)
        return min + ':' + s
    },
    getCurr() { //音频进度改变时触发
 
        this.curr = parseInt(this.$refs.audio.currentTime)
 
        this.progress = this.curr / this.duration * 100
    },
    showLong() { //音频加载成功后获取时长
        this.duration = parseInt(this.$refs.audio.duration)
 
 
    },
    changeLong() { //改变进度
        let ct = this.progress * this.duration / 100
        if (!isNaN(ct)) {
            this.$refs.audio.currentTime = ct
        }
 
        console.log(this.progress)
    },
    plays() { //播放暂停控制
 
        if (this.is_stop) {
            this.$refs.audio.play()
        } else {
            this.$refs.audio.pause()
        }
        // this.is_stop=!this.is_stop
 
    },
    requestLivingData() {
        const { id } = this.$route.params
        this.voiceID = id;
        requestDataTGPMethod(
            "requestData",
            "v4/voice/list",
            { type: 0, page: 1, pageSize: 1, id },
            "getBannerData",
            "0"
        );
    },
       followedBtn() {
            // 通用协议地址
            // iOS 下载地址
            var iOSDownloadUrl = "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
            // Android 下载地址
            var androidDownloadUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock"

            var commSchemeUrl = 'https://api.tinggupiao.com.cn/';
            let u = navigator.userAgent
            var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIos) {
                commSchemeUrl = commSchemeUrl + encodeURIComponent('action:9999?url=127.0.0.1&&fileName=VoiceDetail&&haveNav=0&&title=语音详情&&statusColor=0X453723&&bgColor=0X453723&&firstLoad={"id":' + this.voiceID +"}")
                console.log(commSchemeUrl);
                if (u.toLocaleLowerCase().match("qq")) {
                    commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&ios_schema=" + encodeURIComponent('scxListenStock://' +'action:9999?fileName=VoiceDetail&&haveNav=0&&title=语音详情&&statusColor=0X453723&&bgColor=0X453723&&firstLoad={"id":' + this.voiceID +"}");
                }
                window.location.href = commSchemeUrl;
            } else {
                console.log(commSchemeUrl);
                 commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&android_schema=" + encodeURIComponent('scxlistenstock://' + 'action:9999?fileName=VoiceDetail&&haveNav=0&&title=语音详情&&statusColor=0X453723&&bgColor=0X453723&&firstLoad={"id":' + this.voiceID +"}");
                window.location.href = commSchemeUrl;
            }
            // 3秒后没打开，直接跳转下载页面
            var appDownload = setTimeout(function () {
                console.log("下载app");
                if (isIos) {
                    window.location.href = iOSDownloadUrl;
                } else {
                    window.location.href = androidDownloadUrl;
                }
            }, 3000);
            if (!isIos) {
                document.addEventListener('visibilitychange webkitvisibilitychange', function () {
                    // 页面隐藏，打开app，清除下载
                    if (document.hidden || document.webkitHidden) {
                        clearTimeout(appDownload)
                    }
                })
                window.addEventListener('pagehide', function () {
                    clearTimeout(appDownload)
                })
            }
        },
  }
}
</script>
<style lang="scss" scoped>
.is-flex{
    display: flex;
}
.share_article{
  height: 100%;
//   padding: 0px 16px 0px;
  .share_content{
    //   width: 100;
    //   padding-top: 24px;
      .share_video{
        //   height: 200px;
          padding: 0px 26px 0px;
          .title_img{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .audio_img{
                  width: 128px; 
                  height: 128px;
                  padding-top: 72px;
              }
              .audio_title{
                  padding-top: 38px;
                  font-size: 18px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
              }
          }
          .play_audio{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 99px;
              .ctol{
                  padding-top: 68px;
              }
              .custom-button{
                width: 82px;
                height: 20px;
                background: #F3F3F3;
                border-radius: 10px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                text-align: center;
                line-height: 20px;
            }
          }
          
      }
      .share_content_title{
            // height: 77px;
            // line-height: 77px;
            padding: 0px 16px 0px;
            .video_title{
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 500;
                color: #333333;
                padding-top: 24px;
            }
            .view_counts{
                padding-top: 12px;
                padding-bottom: 24px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
            
      }
      .lecturer_message{
          padding: 0px 16px 0px;
          .lecturer{
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                padding:24px 0px 8px 0px;
          }
          .share_content_message{
            padding-top: 12px;
            height: 74px;
            align-items: center;
            justify-content: space-between;
            .share_message_left{
                .head_portrait{
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                }
                .name_time{
                    padding-left: 8px;
                    .name{
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                    }
                    .time{
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                    }
                }
            }
            .share_message_right{
                .followed_btn{
                        width: 74px;
                        height: 30px;
                        background: #F8F8F8;
                        border-radius: 15px;
                        border: 0px;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #EF0923;
                }
            }
          }
          .introduce{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                text-align: justify
          }
      }
  }
}
/deep/ .prism-player {
      z-index: 1;
      .prism-big-play-btn {
        display: none;
        top: 50%;
        left: 50% !important;
        transform: translate(-50%, -50%);
      }
      height: none;
      .prism-controlbar {
        .prism-controlbar-bg {
          display: inline-block;
        }
        .prism-cc-btn {
          display: none;
        }
        .prism-live-display {
          display: none;
          visibility: hidden;
        }
      }
    }
    p {
    font-size: 15px;
}
</style>