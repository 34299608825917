import { render, staticRenderFns } from "./ShareVoice.vue?vue&type=template&id=6e626d64&scoped=true&"
import script from "./ShareVoice.vue?vue&type=script&lang=js&"
export * from "./ShareVoice.vue?vue&type=script&lang=js&"
import style0 from "./ShareVoice.vue?vue&type=style&index=0&id=6e626d64&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e626d64",
  null
  
)

export default component.exports